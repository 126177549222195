<template>
  <Splitter class="dashboard" stateKey="dashboardSplitterState">
    <SplitterPanel :minSize="10" :size="20">
      <h1>Dashboard</h1>
      <h3>relogin in: {{ reloginIn }}</h3>
      <Tree
        class="p-p-0"
        :value="treeMenuNodes"
        :expandedKeys="treeMenuExpandedNodes"
        :loading="isMenuUpdating"
        selectionMode="single"
        @nodeSelect="onNodeSelect"
        v-model:selectionKeys="treeMenuSelectedNode"
      />
    </SplitterPanel>
    <SplitterPanel :minSize="20" :size="80">
      hello!
      <router-view />
    </SplitterPanel>
  </Splitter>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
import Splitter from "primevue/splitter";
import SplitterPanel from "primevue/splitterpanel";
import Tree from "primevue/tree";
import API from "../API";
// import { Toast } from "../internals/SweetAlerts";
// TODO: get analysis from backend via API

export default {
  name: "dashboard",
  data: () => {
    return {
      now: Date.now(),
      reloginIn: "",
      isMenuUpdating: false,
      treeMenuSelectedNode: null,
      treeMenuNodes: [
        {
          key: "analysis",
          label: "Анализы",
          data: "analysis",
          icon: "pi pi-fw pi-copy",
          children: [],
        },
        {
          key: "users",
          label: "Люди",
          data: "users",
          icon: "pi pi-fw pi-users",
          to: { name: "users" },
          children: [
            {
              key: "users/0",
              label: "Пользователь 1",
              data: "user-###",
              icon: "pi pi-fw pi-user",
            },
            {
              key: "users/1",
              label: "Пользователь 2",
              data: "user-###",
              icon: "pi pi-fw pi-id-card",
            },
          ],
        },
        {
          key: "logout",
          label: "Выход",
          icon: "pi pi-fw pi-power-off",
          command() {
            API.clearSessionToken();
            location.href = "/";
          },
        },
      ],
      treeMenuExpandedNodes: {},
    };
  },
  _interval: 0,
  components: {
    Splitter,
    SplitterPanel,
    Tree,
  },
  computed: {},
  mounted() {
    this.updateCurrentMenuSelection(this.$route);
    this.$options._interval = setInterval(() => {
      const exp = API.getExpireIn();
      this.reloginIn = (exp * 1e-3).toFixed(0);
      if (exp <= 0) {
        // Toast.fire({
        //   icon: "info",
        //   title: "Ваша сессия подошла к концу",
        // });
        this.$toast.add({
          severity: "info",
          summary: "",
          detail: "Ваша сессия подошла к концу",
        });
        this.$router.push({ name: "login" });
        if (this.$options._interval) {
          clearInterval(this.$options._interval);
          this.$options._interval = 0;
        }
      }
    }, 500);
  },
  updated() {
    this.updateCurrentMenuSelection(this.$route);
  },
  unmounted() {
    if (this.$options._interval) {
      clearInterval(this.$options._interval);
      this.$options._interval = 0;
    }
  },
  methods: {
    updateCurrentMenuSelection(route) {
      const frontendPath = API.getFrontendRootPath();
      const key = route.path.slice(
        route.path.indexOf(frontendPath) + frontendPath.length
      );
      this.treeMenuSelectedNode = { [key]: true };
      if (!this.treeMenuExpandedNodes) this.treeMenuExpandedNodes = {};
      this.treeMenuExpandedNodes[key] = true;
    },
    onNodeSelect(node) {
      console.log(node);
      if (typeof node?.command === "function") {
        node.command(node);
      } else if (typeof node?.to === "string") {
        if (node.to[0] === "/") {
          this.$router.push({ path: node.to });
        } else {
          this.$router.push({ name: node.to });
        }
      } else if (typeof node?.to === "object") {
        this.$router.push(node.to);
      } else if (typeof node?.url === "string") {
        location.href = node.url;
      }
    },
  },
};
</script>

<style scoped>
.dashboard {
  height: 100%;
}
</style>
