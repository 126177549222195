<template>
  <div class="loginChooseUser p-grid nested-grid p-ai-center p-m-0">
    <Card class="p-mx-auto p-col-8 p-md-4 p-lg-3 p-xl-3">
      <template #title>
        <div class="p-text-center">Вход</div>
      </template>
      <template #content>
        <div class="p-fluid p-grid">
          <div class="p-field p-col-12 p-m-0 p-pb-0">
            <Listbox
              v-model="selectedUser"
              :options="availableUsers"
              optionLabel="userName"
              optionGroupLabel="label"
              optionGroupChildren="items"
            />
          </div>
        </div>
      </template>
      <template #footer>
        <Button
          class="p-col-12"
          @click="chooseUser"
          :disabled="isNextButtonDisabled"
          label="Далее"
        />
      </template>
    </Card>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
import API from "../API";
import Button from "primevue/button";
import Card from "primevue/card";
import Listbox from "primevue/listbox";
import { Toast } from "../internals/SweetAlerts";

export default {
  name: "loginChooseUser",
  components: {
    Button,
    Card,
    Listbox,
    // Dialog,
  },
  data: () => {
    return {
      now: Date.now(),
      reloginIn: "",
      availableUsers: [],
      selectedUser: null,
      isRequestInProgress: false,
    };
  },
  _interval: 0,
  mounted() {
    this.$options._interval = setInterval(() => {
      const exp = API.getExpireIn();
      this.reloginIn = (exp * 1e-3).toFixed(0);
      if (exp <= 0) {
        Toast.fire({
          icon: "info",
          title: "Ваша сессия подошла к концу",
        });
        this.$router.push({ name: "login" });
      }
    }, 500);
    this.getAvailableUsers();
  },
  unmounted() {
    clearInterval(this.$options._interval);
  },
  computed: {
    isNextButtonDisabled() {
      return !this.selectedUser || this.isRequestInProgress;
    },
  },
  methods: {
    async getAvailableUsers() {
      console.log("get users");
      this.isRequestInProgress = true;
      const response = await API.auth_getSessionUsers().catch((e) => e);
      if (response instanceof Error) {
        console.error(response);
        return;
      }

      if (response.status === "success") {
        const users = response.data.sort((e1, e2) =>
          e1.userName < e2.userName ? 1 : e1.userName > e2.userName ? -1 : 0
        );
        console.log(users);
        for (let i = 0; i < users.length; i++) {
          const userType = users[i].userType;
          let group = null;
          for (let j = 0; j < this.availableUsers.length; j++) {
            if (this.availableUsers[j].userType === userType) {
              group = this.availableUsers[j];
              break;
            }
          }
          if (!group) {
            // TODO: move userType translation to special function
            this.availableUsers.push({
              label: userType === "person" ? "Пациенты" : "Другие",
              userType,
              items: [],
            });
            group = this.availableUsers[this.availableUsers.length - 1];
          }

          group.items.push(users[i]);
          if (users.length === 1) {
            this.selectedUser = group.items[0];
          }
        }
      } else {
        // logout?
      }
      this.isRequestInProgress = false;
    },
    async chooseUser() {
      this.isRequestInProgress = true;
      // eslint-disable-next-line prettier/prettier
      const response = await API.auth_setSessionUser(this.selectedUser).catch((e) => e);
      if (response instanceof Error) {
        console.error(response);
        return;
      }

      if (response.status === "success") {
        console.log("push dashboard");
        this.$router.push({ name: "dashboard" });
      } else {
        // unexpected error?
      }
      this.isRequestInProgress = false;
    },
  },
};
</script>

<style>
.loginChooseUser {
  background: #ccc;
  width: 100%;
  height: 100%;
}
</style>
